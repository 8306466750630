import React, { useState, useEffect } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Button, Flex, Input } from '@chakra-ui/core'

import { useGlobalState, setGlobalState, setStorageState } from '../store'

//get domain name with port
const domain = window.location.host

function Login(props) {
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')


  setGlobalState('pageClass', 'users')

  const resetPassword = async e => {
    if (!username || username === '') {
      toast('Por favor introduza o email')
    }

    const response = await axios.post(`/auth/forgot`, {
      email: username
    })
    toast('Verifique a sua caixa de correio')
  }

  const loginUser = async e => {
    e.preventDefault()

    if (username === '' || password === '') {
      return toast('Por favor introduza o email e password')
    }

    try {
      const response = await axios.post(`/auth`, {
        email: username,
        emailDomain: `${username}@editcors.com`,
        password
      })
      if (
        response.data &&
        response.data.token &&
        username === 'hugo@cors.digital'
      ) {
        await setStorageState('loggedIn', response.data)
        props.history.push('/dashboard')
      }
    } catch (e) {
      console.log(e)
      setStorageState('loggedIn', null)
      toast('Verifique os seus dados')
    }
  }

  const checkLoggedIn = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    if(!token ||loggedIn) return
    //lets query the backend to complete my profile
    const response = await axios.post(`/auth/profile`, {
      token
    })
    
    if (response) {
      await setStorageState('loggedIn', response.data)
      // props.history.push('/dashboard')
    }
  }
  //lets deted location change and if it has a token param set the login and clear the token from the url
  useEffect(() => {
    checkLoggedIn()
    return () => {}
  },[
    props.location.search, loggedIn
  ]
  )

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
      backgroundImage={'url(/images/editCORSIMG.jpg)'}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
    >
      <Box
        className={'loginwrap'}
        maxW="md"
        bg="white"
        width={'40%'}
        height={'100vh'}
        padding={'2em'}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={'absolute'}
        left="0"
        background={'rgba(0,0,0,0.8)'}
      >
        <div className={'login-container'}>
          <div>
            <h1>EditCors </h1>
            <form className="login-form" onSubmit={loginUser} data-login-form>
              <Input
                data-login-email
                onChange={e => setUsername(e.target.value)}
                placeholder="Email"
              />
              <Input
                type="password"
                data-login-password
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
              />

              <Button
                onClick={e => loginUser(e)}
                variantColor="teal"
                value="Submit!"
                width={'100%'}
              >
                Submit!
              </Button>
            </form>
            <Button
                variantColor="blue"
                value="Submit!"
                width={'100%'}
                mt={'1em'}
              >
                <a class="button google" href={`https://api.ecommerce.editcors.com/api/auth/${domain}/google`}>Sign in with Google</a>
            </Button>
           
            <br />
            <br />
            <Button
                onClick={e => loginUser(e)}
                variantColor="grey"
                value="Submit!"
                width={'100%'}
                mt={'1em'}
              >
            <a href={'#'} onClick={() => resetPassword()}>
              Reset Password
            </a>
            </Button>
          </div>
        </div>
      </Box>
    </Flex>
  )
}

export default withRouter(Login)
